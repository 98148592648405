<template>
  <div class="login_form">
    <!-- pc login  -->
    <div class="pc_dialog hidden-xs-only" v-if="showDialog">
        <div class="dialog">
            <div class="dialog_container">
                <div class="dialog_thumb">
                    <div class="dialog_group">
                        <div class="iconfont icon-close icon-back-line-free dialog_back" @click="setSignType()" v-show="signType=='singup'||signType=='login'"></div>
                        <div class="dialog_title">{{ $t('key11') }}/ {{ $t('key12') }}</div>
                        <div class="dialog_tips flex">
                            <div class="tips_info" v-show="signType=='login'||!signType"> {{ $t('key683') }}<span @click="setSignType('singup')"> {{ $t('key12') }}.</span></div>
                            <div class="tips_info" v-show="signType=='singup'">{{ $t('key684') }}<span @click="setSignType('login')"> {{ $t('key11') }}.</span></div>
                        </div>
                        <!-- <vue-social-login-button provider="line">登录LINE</vue-social-login-button> -->
                        <div class="dialog_content flex_center" v-show="!signType">
                            <div class="dialog_swiper">
                                <swiper :options="loginOption" ref="mySwiper" class="swiper_content">
                                    <swiper-slide class="slide_content " v-for="(i,index) in logCate" :key="index" >
                                        <div class="icon_container flex_center" :style="{'background':i.bg,'color':i.color}"  @click="handleClick(i)" >
                                            <div class="" :id="i.class"  @click="handleClick(i)"></div>
                                            <div class="flex_center iconfont icon_content " :class="i.icon" v-show="!i.class"></div>
                                        </div>
                                        <div class="icon_name">{{ i.name }}</div>
                                    </swiper-slide>         
                                </swiper>
                            </div>
                           
                            <div class="swiper-button-prev iconfont icon-back-line-free btn_icon flex_center"></div>
                            <div class="swiper-button-next iconfont icon-enter-line-free btn_icon  flex_center"></div>
                        </div>
                      
                        <!-- sign up -->
                        <div class="dialog_content" v-show="signType=='singup'" >
                            <el-form label-position="top" label-width="80px" :model="signForm" ref="ruleForm1" :rules="rules" >
                                <el-form-item label="" prop="email">
                                  <el-input v-model="signForm.email" :placeholder="$t('key689')"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="code">
                                    <div class="code_item flex_sb">                           
                                        <el-input v-model="signForm.code" :placeholder="$t('key691')"></el-input>
                                        <div class="code_img" @click="getCode()"><div class="code_btn">{{ $t('key693') }}</div></div>
                                    </div>                      
                                </el-form-item> 
                                <el-tag type="warning" style="margin-bottom:16px" v-if="!sendCode">{{ $t('key694') }}</el-tag>
                                <el-form-item label="" prop="password">
                                  <el-input v-model="signForm.password" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="confirmPassword">
                                  <el-input v-model="signForm.confirmPassword" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                            </el-form>
                             <div class="dialog_btn" @click="signup()">Sign up</div>
                        </div>
                        <!-- login -->
                        <div class="dialog_content" v-show="signType=='login'" >
                            <el-form label-position="top" label-width="80px" :model="signForm" ref="ruleForm2" :rules="rules" >
                                <el-form-item label="" prop="email">
                                  <el-input v-model="signForm.email" :placeholder="$t('key689')"></el-input>
                                </el-form-item> 
                                <!-- <el-form-item label="" prop="code">
                                    <div class="code_item flex_sb">
                                        <el-input v-model="signForm.code" :placeholder="$t('key691')"></el-input>
                                        <div class="code_img" @click="getCode()"><div class="code_btn">{{ $t('key693') }}</div></div>
                                    </div>                      
                                </el-form-item>  -->
                                <el-tag type="warning" style="margin-bottom:16px" v-if="!sendCode">{{ $t('key694') }}</el-tag>
                                <el-form-item label="" prop="password">
                                  <el-input v-model="signForm.password" :placeholder="$t('key692')" type="password"></el-input>
                                </el-form-item> 
                            
                            </el-form>
                             <div class="dialog_btn" @click="login()">{{ $t('key11') }}</div>
                        </div>
                        <div class="dialog_text">
                            <p>{{ $t('key685') }}</p>
                            <div class="flex_center">
                                <div class="link_tab" @click="toPage('/privacyPolicy')">{{ $t('key36') }}</div>
                                <span class="link_line">/</span> 
                                <div class="link_tab"  @click="toPage('/terms')"> {{ $t('key35') }}</div>
                                <span class="link_line">/</span> 
                                <div class="link_tab" @click="toPage('/cookiePolicy')">{{ $t('key37') }}</div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="btn_close iconfont icon-close" @click="closeLogin()"></div>
                </div>
                <!-- <div class="g_id_signin"></div> -->
                 <!-- <div class="google_sigin" id="google_sigin"></div> -->
                <!-- <div class="g-signin2" data-onsuccess="onSignIn" style="width: 100px;height: 40px; background-color: #fff;">google</div> -->
            </div>
        </div>
    </div>
    <!-- mobile login -->
    <div class="mobile_container hidden-sm-and-up flex_col" v-show="showDialog">
        <div class="container_thumb ">
            <div class="thumb_logo flex_center">
                <img src="../assets/image/logo.png" alt="">
            </div>
            <div class="thumb_title">{{ $t('key686') }}</div>
            <div class="dialog_tips flex_center">
                <div class="tips_info" v-show="signType=='login'||!signType"> {{ $t('key683') }}<span @click="setSignType('singup')"> {{ $t('key12') }}.</span></div>
                <div class="tips_info" v-show="signType=='singup'">{{ $t('key684') }}<span @click="setSignType('login')">{{ $t('key11') }}.</span></div>
            </div>
            <div class="login_list" v-show="!signType">
                <div class="login_type" v-for="(item,index) in logCate" :key="index">

                    <div class="login_item flex_center"  v-if="item.type==1" >
                        <div class="google_mobile_sigin" id="google_mobile_sigin"></div>
                    </div>
                    <div class="login_item flex_center"  :style="{'background':item.bg,'color':item.color}" @click="handleClick(item)"  v-if="item.type!=1">
                        <div class="flex_center iconfont icon_content icon_type" :class="item.icon" ></div>
                        
                        <div class="iten_name">Sign in with {{ item.name }}</div>
                        
                    </div>
                </div>
            </div>
             <!-- sign up -->
             <div class="dialog_content" v-show="signType=='singup'" >
                <el-form label-position="top" label-width="80px" :model="signForm" ref="ruleForm1" :rules="rules" >
                    <el-form-item label="" prop="email">
                        <el-input v-model="signForm.email" :placeholder="$t('key689')"></el-input>
                    </el-form-item> 
                    <el-form-item label="" prop="code">
                        <div class="code_item flex_sb">
                            <el-input v-model="signForm.code" :placeholder="$t('key691')"></el-input>
                            <div class="code_img" @click="getCode()"><div class="code_btn">{{ $t('key693') }}</div></div>
                        </div>                      
                    </el-form-item> 
                    <el-tag type="warning" style="margin-bottom:16px" v-if="!sendCode">{{ $t('key694') }}</el-tag>
                    <el-form-item label="" prop="password">
                        <el-input v-model="signForm.password" :placeholder="$t('key692')" type="password"></el-input>
                    </el-form-item> 
                    <el-form-item label="" prop="confirmPassword">
                        <el-input v-model="signForm.confirmPassword" :placeholder="$t('key692')" type="password"></el-input>
                    </el-form-item> 
                </el-form>
                    <div class="dialog_btn" @click="signup()">{{ $t('key12') }}</div>
            </div>
            <!-- login -->
            <div class="dialog_content" v-show="signType=='login'" >
                <el-form label-position="top" label-width="80px" :model="signForm" ref="ruleForm2" :rules="rules" >
                    <el-form-item label="" prop="email">
                        <el-input v-model="signForm.email" :placeholder="$t('key689')"></el-input>
                    </el-form-item> 
                    <!-- <el-form-item label="" prop="code">
                        <div class="code_item flex_sb">
                            <el-input v-model="signForm.code" :placeholder="$t('key691')"></el-input>
                            <div class="code_img" @click="getCode()"><div class="code_btn">{{ $t('key693') }}</div></div>
                        </div>                      
                    </el-form-item>  -->
                    <el-tag type="warning" style="margin-bottom:16px" v-if="!sendCode">{{ $t('key694') }}</el-tag>
                    <el-form-item label="" prop="password">
                        <el-input v-model="signForm.password" :placeholder="$t('key692')" type="password"></el-input>
                    </el-form-item> 
                
                </el-form>
                    <div class="dialog_btn" @click="login()">{{ $t('key11') }}</div>
            </div>
        </div>
        <div class="dialog_text">
            <p>{{ $t('key685') }}</p>
            <div class="flex_center">
                <div class="link_tab" @click="toPage('/privacyPolicy')">{{ $t('key36') }}</div>
                <span class="link_line">/</span> 
                <div class="link_tab" @click="toPage('/terms')">{{ $t('key35') }}</div>
                <span class="link_line">/</span> 
                <div class="link_tab" @click="toPage('/cookiePolicy')">{{ $t('key37') }}</div>
            </div>
        </div>
        <div class="btn_close iconfont icon-close" @click="closeLogin" v-show="signType==''"></div>
        <div class="iconfont icon-close icon-back-line-free btn_close" @click="signType=''" v-show="signType=='singup'||signType=='login'"></div>
    </div>
   <div id="iframe-container"></div>
    <!-- <iframe :src="thirAuth" class="iframBox"></iframe> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import cookies from "../utils/cookie";
import {thirdSign} from '../mixins/thirdSign'

import vueSocialLoginButton from 'vue-social-login'

export default {
    mixins:[thirdSign],
    components:{
       swiper,
       swiperSlide,
       vueSocialLoginButton
    },
    data(){
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error(this.$t('key692')));
            } else if (value !== this.signForm.confirmPassword) {
            callback(new Error(this.$t('key688')));
            } else {
            callback();
            }
        };
        var validateEmail = (rule, value, callback) => {
            let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value === '') {
               callback(new Error(this.$t('key689')));
            } else if (!regex.test(value)) {
              callback(new Error(this.$t('key690')));
            } else {
              callback();
            }
        };
        return{
           
            signType:'',
            codeImage:'',
            loginOption: {
                loop: false,
                autoplay: false,
                slidesPerView:4,
                spaceBetween: 20,
               
                // 显示分页
                pagination: {
                // el: ".swiper-pagination",
                clickable: true, //允许分页点击跳转
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                observer:true,  //修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,  //修改swiper的父元素时，自动初始化swiper
                observeSlideChildren:true,
                on: {
                    click: (event) =>  {
                    },
                },
                breakpoints: { 
                  //当宽度小于等于320
                  767: {
                    slidesPerView: 1.1,
                  },
  
                },
               
            },
            
            
            signForm:{
                email:'',
                password:'',
                confirmPassword:'',
                code:'',
                uuid:'',
                type:'1',
                // 类型 1登录邮箱 2忘记密码邮箱 3修改密码邮箱 4注册邮箱
                
            },
            rules:{
                email: [  { required: true, validator:validateEmail, trigger: 'blur' } ],
                password: [  { required: true, message: this.$t('key692'), trigger: 'blur' } ],
                confirmPassword: [  { required: true, validator:validatePass2, trigger: 'blur' } ],
                code: [  { required: true, message: this.$t('key691'), trigger: 'blur' } ],
            },
            inviteUserCode:'',
            type:1,
            sendCode:true,
            sendEndTime:60,
          

        }
    },
    created(){
        let inviteUserCode = this.$route.query.inviteUserCode
        if(inviteUserCode){
            this.inviteUserCode = inviteUserCode
        }
        console.log('inviteUserCode login_form',inviteUserCode)
      
    },
    mounted(){
        this.getGoogle()
    },
    methods:{
        
        setSignType(type){
            if(!type){
                this.signType = ''
                // this.getGoogle()
                return
            }
            this.signType = type
            this.sendCode = true
            if(type=='login'){
                this.signForm.type='1'
            }
            if(type == 'singup'){
                this.signForm.type='4'
            }
        },
      
        
        closeLogin(){
          this.$store.commit('setShowDialog',false)
        },
        
        login(){
            let code = ''
            let email = this.signForm.email
            let password = this.signForm.password
            
            let _this = this
            this.$refs['ruleForm2'].validate((valid) => {
                if (valid) {
                    this.$axiosApi.login({name:email,password,code}).then(res=>{         
                        if(res.code == 200){
                            this.$message.success(res.msg)
                            cookies.setToken(res.data.token)
                            setTimeout(()=>{
                                
                                _this.$store.commit('setShowDialog',false)
                                
                                location.reload()
                            },1000) 
                        }else{
                            this.sendCode = true
                        }
                    })
                  
                } else {
                
                }
            })
            
        },
        signup(){
            let code = this.signForm.code
            let email = this.signForm.email
            let password = this.signForm.password
            let confirmPassword = this.signForm.confirmPassword
            let inviteUserCode = this.inviteUserCode
            let _this = this
            this.$refs['ruleForm1'].validate((valid) => {
                if (valid) {
                    this.$axiosApi.register({name:email,password,code,confirmPassword,inviteUserCode}).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.msg)
                            setTimeout(()=>{
                                _this.$store.commit('setShowDialog',false)
                            },1000)
                            
                        }else{
                            this.sendCode = true
                        }
                    })
                  
                } else {
                
                }
            })
           
        },
        toPage(path){
            this.$store.commit('setShowDialog',false)
            
            let lang = localStorage.getItem('lang')
            this.$router.push(path+'/'+lang)
        },
        getCode(){
            let code = this.signForm.code
            let account = this.signForm.email
            let password = this.signForm.password
            let type = this.signForm.type
            this.$axiosApi.getCode({code,password,account,type}).then(res=>{
                if(res.code == 200){          
                    this.sendCode = false
                  
                }
            })
        },
        threeOauth(){
            let type = this.thirdLog.type
            this.$axiosApi.threeOauth({type}).then(res=>{
                if(res.code == 200){
                    console.log('res.',res)
                    this.thirAuth = res.data.data
                }
            })
        },
        

    },
    computed:{
        showDialog(){return this.$store.state.showDialog}
    }
}
</script>

<style lang="scss" scoped>
.dialog{
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:#0000008f;
    z-index: 9999;
    .dialog_back{
        background: #fff;
        color: #0f0f0f;
        font-size: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        position: relative;
        top:-14px;
        
    }
    .dialog_container{
      padding:16px 72px;
      width: 644px;
      max-width: 100%;
      margin:0 auto;
      position: relative;
      top:25%;
      
      .dialog_thumb{
        background:#fff;
        border-radius: 6px;
      }
      .dialog_group{
       
        background: 0 0 / 100% url(https://static.lootbar.gg/src/img/img_lootbar_bg.dabe53a4.png) no-repeat;
        border-radius: 6px;
        text-align: left;
        width: 100%;
        padding:24px 16px;
        position: relative;
        .dialog_title{
          color: #1f2128;
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: 600;
        }
      
        .dialog_content{
            position: relative;
            
            .swiper-button-next,.swiper-button-prev{
                // background:url();
                position: static;
                margin:0;
                position: absolute;
                top:calc(50% - 22px);
            }
            .swiper-button-prev{
                left:0;
            }
            .swiper-button-next{
                right:0;
            }
            .btn_icon{
                width: 24px;
                height: 24px;
                border:1px solid #d2d3d4;
                border-radius: 2px;
                background:#fff;
                font-size: 16px;
            }
            .dialog_swiper{
                padding:74px 0;
                width: 404px;
                .swiper_content{
                    padding-bottom:30px;
                    
                    .slide_content{  
                        padding-top:10px;
                        position: relative; 
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        &:hover{
                            .icon_name{
                                display: block;
                            }
                        }
                        .icon_container{
                            
                            width: 44px;
                            height: 44px;
                            border-radius: 50%;
                            overflow: hidden;
                            position: relative;
                            transform: scale(1.2);
                            &:hover{
                                opacity: .7;
                                
                            }
                            .icon_content{
                                font-size: 24px;
                            }
                            
                        }
                        .icon_name{
                            display: none;
                            text-align: center;
                            transition: all .1s linear;
                            color:#000;
                            font-size: 12px;
                            margin-top:8px;
                            position: absolute;
                            left:0;
                            right:0;
                            bottom:-30px;
                            margin:0 auto;
                        }
                        
                    }
                }
            }
        }
         .dialog_text{
            font-size: 12px;
            line-height: 20px;
            margin-top: 12px;
            color:#77808c;
            text-align: center;
            .link_tab{
                cursor: pointer;
                // text-decoration: underline;
                &:hover{
                    text-decoration: underline;
                }
                // text-decoration-color: transparent;
            }
            .link_line{
                margin:0px 8px;
                color:#e8e9ed;
            }
            
            
          }
          
      }
      .btn_close{
        background:#fff;
        color:#0f0f0f;
        font-size: 24px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        top:16px;
        right:10px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity: 0.7;
        }
      }
    }
   
}
.iframBox{
    position: fixed;
    top: 100px;
    left: 100px;
    z-index: 999999;
    width: 800px;
    height: 600px;
}
/deep/.el-input__inner{
        background:#eff2f5;
        height: 46px;
        border:1px solid #eff2f5;
        box-sizing: border-box;
        &:focus{
        border:1px solid #ffd633;
        }
    }
    .dialog_btn{
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 48px;
        margin-top: 40px;
        font-weight: 700;
        cursor: pointer;
        background:#ffcb2e;
        border-radius: 6px;
        text-align: center;
        &:hover{
            opacity: 0.7;
        }
    }
    .dialog_tips{
        margin:10px 0;
        color:#1f212899;
        font-size: 14px;
        span{
            color:#ffa100;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
            
            // text-decoration-color: transparent;
        }
    }
    .code_item{
        .code_img{
            width:50%;
            padding:0 10px;
            cursor: pointer;
            .code_btn{
                // padding:0 4px;
                height: 46px;
                border-radius: 4px;
               
                color:#77808c;
                border:1px solid #d2d3d4;
                cursor: pointer;
                text-align: center;
                line-height: 46px;
                color:#000;
                font-size: 14px;
                background: #ffcb2e;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .code_resect{
            height: 46px;
            text-align: center;
            line-height: 46px;
            border-radius: 4px;
            font-size: 12px;
            color:#77808c;
            border:1px solid #d2d3d4;
            
        }
    }
.mobile_container{
    position: fixed;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: 0 0 / 100% url(https://static.playsave.com/src/img/img_playsave_bg.dabe53a4.png) no-repeat;
    background-color: #fff;
    .container_thumb{
        max-width: 407px;
        padding-top:48px;
        flex:1;
        .thumb_logo{
            >img{
                width:180px;
            }
            
        }
        .thumb_title{
            margin: 32px;
            color: #1f2128;
            position: relative;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }
        .login_list{
            
            margin: 24px 0;
            font-size: 14px;
            padding: 0 16px;
            .login_type{
                width:100%;
                margin-bottom:8px;
                border-radius: 4px;
                height: 48px;
                overflow: hidden;
                &:hover{
                    opacity: 0.7;
                    cursor: pointer;
                }
                &:first-child{
                    // border: 2px solid #ebecf0;
                }
                .login_item{
                    height: 100%;
                }
                .icon_type{
                    margin-right:6px;
                    font-size: 20px;
                }
            }
        }
    }
    .btn_close{
        position: absolute;
        top:10px;
        left:10px;
        background:#fff;
        color:#0f0f0f;
        font-size: 24px;
        height: 40px;
        width: 40px;
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity: 0.7;
        }
    }
    .dialog_text{
        font-size: 12px;
        line-height: 20px;
        margin-top: 12px;
        color:#77808c;
        text-align: center;
        padding-bottom:100px;
        .link_tab{
            cursor: pointer;
            // text-decoration: underline;
            &:hover{
                text-decoration: underline;
            }
            // text-decoration-color: transparent;
        }
        .link_line{
            margin:0px 8px;
            color:#e8e9ed;
        }
        
        
    }
          
}
.g_id_signin{
    width: 45px;
    height: 45px;

}
/deep/.nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf{
    width: 68px!important;
}


</style>