<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>



import cookies from './utils/cookie';

export default {
  name: 'App',
  components: {
    // appMian
  },
  provide () { 
    return {
      reload: this.reload
    }
  },
  data(){
    return{ 
      isRouterAlive: true
    }
    
  },
  created(){
      let locale = localStorage.getItem('lang')
      
      let lang = this.$route.params.lang?this.$route.params.lang:''
      if(lang&&lang!=locale){
        location.reload()
      }
      
      console.log('router appppp',this.$route,locale,lang)
      this.$store.dispatch('getLanguage',{dictType:'t_goods_language'})
      this.$store.dispatch('getCurrency',{dictType:'t_currency'})
      let token = cookies.getToken()
      if(token){
        this.$store.dispatch('getUserInfo')
      }
      // this.onConversationsAPIReady() 
     this.getLocaleUrl()
   
  },
  mounted(){
  
  },
  methods:{
    onConversationsAPIReady() {
    
      if (window.HubSpotConversations) {
        
        } else {
          /*
            Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
            These callbacks will be called once the external API has been initialized.
          */
          // window.hsConversationsOnReady = [this.onConversationsAPIReady()];
        }
        // window.HubSpotConversations.on('contactAssociated', (payload) => {
        //   console.log('contactAssociated',payload.message);
        // });
      window.addEventListener("load", () => {
        window._hsq = window._hsq || [];
          _hsq.push(["identify", {
            email: "joker@163.com",   // 访客邮箱（默认识别字段）
            firstName: "zhang",          // 名
            lastName: "san",            // 姓
            phone: "1234567890",         // 电话（可选）
            external_user_id: "21" // 自定义属性（需要预先设置）
          }]);
          window.hsConversationsSettings = {
          loadImmediately: true,
          identificationEmail: 'joker@163.com',
          identificationName:'zhang san',
          identificationToken: '',
        };
        console.log('window',window._hsq)
        window.HubSpotConversations.on('contactAssociated', (payload) => {
          console.log('contactAssociated',payload.message);
        });
        window.HubSpotConversations.on('conversationStarted', (payload) => {
          console.log(
            `Started conversation with id `,payload
          );
        });
        window.HubSpotConversations.on('userInteractedWithWidget', payload => {
          console.log('userInteractedWithWidget',payload.message);
        });    

        window.HubSpotConversations.on('widgetLoaded', payload => {
          console.log('widgetLoaded',payload.message);
        });
        console.log('HubSpot Conversations API:', window.HubSpotConversations);
        // 
      })
     
    },
    reload () {
      this.isRouterAlive = false
      let that = this

      this.$store.commit('setLang_flag',false)
      this.$store.commit('setShowDialog',false)
      this.$store.commit('setCurrency','')
      this.$store.commit('setLang','')
      this.$store.dispatch('getLanguage',{dictType:'t_goods_language'})
      this.$store.dispatch('getCurrency',{dictType:'t_currency'})
      this.$nextTick(function () {
        that.isRouterAlive = true
      })
    },
    getLocaleUrl(){
      let locale = window.location.hostname 
      console.log('locale',locale)
      let index = locale.indexOf('wwww')
      let name = ''
      if(index==-1){
        name = locale
        this.$store.commit('setLocalName',name)
        // document.title = name
        console.log('name',name)
        return
      }
      let arr = locale.split('.')
       name = arr[1]
       document.title = name
      this.$store.commit('setLocalName',name)
      console.log('localearr',arr,name)
    }

  },
  watch:{
    '$route': {
      handler: function(newVal,oldVal) {
        if(newVal.name == oldVal.name){
          this.reload()
        }
          
      } 
    } 
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Oxanium, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #161619;
  background:#eff2f5;

  // min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}
</style>
